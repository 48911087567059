import React from "react"
import Layout from "../components/Layout/layout"
import Typography from '@material-ui/core/Typography'
import SEO from "../components/seo"


const ProjectsPage = () => (
  <Layout>
    <br/>
    <SEO title="Projects" />
    <Typography variant="h3" style={{paddingBottom: '15px'}}>Projects</Typography>
    <div style={{paddingTop: 100}}></div>
    <Typography variant="h5" style={{paddingBottom: '15px'}}>Work in progress ...</Typography>
  </Layout>
);

export default ProjectsPage